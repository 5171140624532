/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { bool, func,  object } from 'prop-types';
import {
    ClickAwayListener, makeStyles, MenuItem, MenuList, Paper,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    wrapSuggestion: {
        position: 'relative',
    },
    autoSuggestion: {
        position: 'absolute',
        backgroundColor: '#FFF',
        zIndex: 10,
        width: '100%',
        padding: '0px 3px',
    },
    addressList: {
        fontSize: '12px',
        padding: '4px',
        fontWeight: '600',
        borderBottom: 'solid 1px #ecdede',
        minHeight: 'auto',
    },
    menuListWrapper: {
        padding: '0px',
    },
    topContainer: {
        '& $autoSuggestion': {
            minWidth: '235px',
            zIndex: '999',
        },
        '& li': {
            padding: '8px',
            '@media screen and (max-width: 767px)': {
                padding: '10px 8px',
            },
        },
    },
}));
const QasSuggestionList = ({
    suggestions, handleAutoCompleteBlur, handlePickAnAddress, handleClickAway, showModel, gfAutoFillAddressEnabled,
}) => {
    const classes = useStyles();

    const getSuggestions = () => {
        if (suggestions
            && suggestions.results
            && suggestions.count
            && suggestions.count > 0) {
            return suggestions.results.map((address) => {
                if (!address.suggestion) {
                    return <></>;
                }
                return (
                    <MenuItem
                        tabindex={0}
                        className={classes.addressList}
                        key={`suggestionAVS${address.suggestion.replace(/\s/g, '')}`}
                        onClick={() => { handlePickAnAddress(address); handleClickAway(false); }}
                    >
                        {address.suggestion}
                    </MenuItem>
                );
            });
        }

        if (suggestions
            && suggestions.results
            && suggestions.count === 0) {
            return (
                <MenuItem
                    className={classes.addressList}
                    key="No_Match_Found"
                >
                    No address match found
                </MenuItem>
            );
        }
        return <></>;
    };

    if (!showModel) {
        return <></>;
    }

    return (
        <div
            className={`${classes.topBox} ${gfAutoFillAddressEnabled ? classes.topContainer : {}}`}
            id="sugestion-list"
            onBlur={handleAutoCompleteBlur}
        >
            <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                    <div className={classes.wrapSuggestion}>
                        <Paper className={classes.autoSuggestion}>
                            <MenuList className={classes.menuListWrapper}>
                                {getSuggestions()}
                            </MenuList>
                        </Paper>
                    </div>
                </div>
            </ClickAwayListener>
        </div>
    );
};
QasSuggestionList.propTypes = {
    handleClickAway: func,
    handleAutoCompleteBlur: func,
    handlePickAnAddress: func,
    suggestions: object,
    showModel: bool,
    gfAutoFillAddressEnabled: bool,
};

QasSuggestionList.defaultProps = {
    handleClickAway: () => {},
    handleAutoCompleteBlur: () => { },
    handlePickAnAddress: () => { },
    suggestions: {
        results: [],
        count: 0,
    },
    showModel: false,
    gfAutoFillAddressEnabled: false,
};

export default QasSuggestionList;
