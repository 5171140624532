/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import Calendar from '@material-ui/icons/Event';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
    object, func, string, array, bool, shape,
} from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import mbpLogger from 'mbp-logger';
import ReactHTMLParser from 'react-html-parser';
import ZipInputField from './Partials/ZipInputField';
import {
    validateProductFilterZipcode,
    clearValidatedZipcode,
    setProductFilterOccasion,
    resetProductFilterZipcodeValidity,
    setUserSubmittedProductFilterZipcode,
} from '../../../../../../state/ducks/App/App-Actions';
import { emitCustomTrackingEvent } from '../../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import {
    getValidatedZipcode,
    getProductFilterOccasion,
    getIsProductFilterZipcodeValid,
    getUserSubmittedProductFilterZipcode,
    getSSRDeviceType,
} from '../../../../../../state/ducks/App/App-Selectors';
import CUSTOM_BREAKPOINTS from '../../../../../helpers/customBreakpoints';
import { actions as tagManagerActions } from '../../../../../../state/ducks/TagManager';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getActiveABTest } from '../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { getBrand } from '../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { selectors } from '../../../../../../state/ducks/App';
import convertAlphaDecToHex from '../../../../../helpers/contentstack/convertAlphaDecToHex';
import GiftFinderProfileContainer from './v3/GiftFinderProfileContainer';
import GiftFinderUniversal from './GiftFinderUniversal/GiftFinderUniversalContainer';
import ComponentErrorBoundary from '../../../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';
import noop from '../../../../../helpers/noop';
import GiftFinderProductContainer from '../../../GiftFinderProduct/GiftFinderProductContainer';
import PerfectGiftFastContainer from './FindThePerfectGift/PerfectGiftFastContainer';
import GiftFindZipCodeBlock from './GiftFindZipCodeBlock';

// styles applied here are default settings of find a gift fast
// when CMS dropdown is set to 'STACKED' we apply specific rules to change the layout presentation
// please refer to the nested selectors below or search #STACKED layout
const styles = (theme) => ({
    root: {
        zIndex: 2,
        position: 'relative',
        margin: '0 15px 15px 15px',
        borderRadius: '5px',
        width: 'auto',
        minHeight: '200px',
        backgroundColor: theme.palette.white,
        boxShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
        border: '1px solid #eee',
        overflow: 'visible',
        [theme.breakpoints.up(768)]: {
            margin: '-50px auto 0 auto', // check "data.margin" for default values
            width: '100%',
            minHeight: '104px',
            borderRadius: '0',
            border: 0,
            // STACKED
            '&#stacked': {
                margin: '-50px auto 0 auto',
                width: '90%',
                '& $cardContentPadding': {
                    paddingTop: '34px',
                    paddingBottom: '34px',
                },
                '& $fagfContainer': {
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                },
                '& $fagfSVG': {
                    display: 'none',
                },
                '& $sectionHeader': {
                    fontSize: '30px',
                    letterSpacing: '10px',
                    margin: '0 -10px 30px 0',
                },
                '& $fagfInputContainer': {
                    width: '100%',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0 20px',
                    '& input,& select': {
                        color: '#666666',
                        fontSize: theme.palette?.text?.fontSize14 || '14px',
                        height: '50px',
                        textAlign: 'center',
                        textAlignLast: 'center',
                    },
                },
                '& $zipContainer, & $dateContainer': {
                    // flex: '1 1 auto',
                    flex: '0 0 33.333333%',
                    marginRight: 0, // overright default

                },
                '& $zipContainer': {
                    '& > div': {
                        paddingRight: '16px',
                    },
                },
                '& $btnContainer': {
                    flex: '0 1 100%',
                },
                '& $btnContainer button': {
                    height: '50px',
                    fontSize: theme.palette?.text?.fontSize20 ||  '20px',
                    width: '40%',
                    margin: '34px auto 0 auto',
                    display: 'flex',
                    borderRadius: '0',
                },
                '& label': {
                    fontWeight: '500',
                },
                '& $arrowIcon,& $calIcon': {
                    right: '15px',
                },
                '& $calIcon': {
                    top: 'calc(50% - 8px)',
                },
            },

            '&#fall-redesign': {
                margin: '0px auto 25px auto',
                '& $sectionHeader': {
                    fontFamily: 'PlayfairDisplayRegular, serif',
                    fontWeight: '400',
                },
                '& $select': {
                    [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                        border: 'none',
                    },
                },
                '& $zipContainer': {
                    '& input': {
                        border: 'none',
                    },
                },
            },
            '&#fall-redesign-full': {
                margin: '-104px auto 0 auto',

                '& $sectionHeader': {
                    fontFamily: 'PlayfairDisplayRegular, serif',
                    fontWeight: '400',
                },
                '& $select': {
                    [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                        border: 'none',
                    },
                },
                '& $zipContainer': {
                    '& input': {
                        border: 'none',
                    },
                },
            },
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            // margin: '-50px auto 0 auto',
            // marginBottom: '50px',
            marginBottom: '18px',
            width: '980px',
            backgroundColor: theme.palette.gfBgColor, // TO DO: Need secondary palette purple in theme
            '&#stacked': {
                width: '980px',
            },
            '&#fall-redesign': {
                width: '100%',
                maxWidth: '1360px',
                position: 'relative',
            },
            '&#fall-redesign-full': {
                width: '100%',
                position: 'relative',
            },
        },
        '& $btnContainer button': {
            [theme.breakpoints.down(325)]: {
                width: '30% !important',
                fontSize: theme.palette?.text?.fontSize14 || '14px',
            },
        },
        [theme.breakpoints.down(767)]: {
            '&#fall-redesign': {
                '& $zipContainer': {
                    '& input::placeholder': {
                        color: '#FFFFFF',
                    },
                    '& input, & select': {
                        color: '#FFFFFF80',
                    },
                },
                '& $dateContainer': {
                    '& input, & select': {
                        color: '#FFFFFF80',
                    },
                },
            },
            '& $giftFinderHorizontal': {
                margin: '0px 0px',
            },
        },
    },
    cardContentPadding: {
        padding: '14px 14px 16px 14px',
        '&:last-child': {
            [theme.breakpoints.down(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                paddingBottom: 14,
            },
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            padding: '24px 10px',
        },
    },
    fagfContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'flex-end',
        },
    },
    sectionheaderContainer: {
        display: 'flex',
        flex: '0 0 100%',
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'center',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            flex: '0 0 auto',
            width: 'auto',
            alignItems: 'center',
        },
    },
    fagfSVG: {
        '& svg': {
            width: '18px',
            margin: '2px 5px 0 0',
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                margin: '0',
                width: '24px',
                maxHeight: '24px',
            },
        },
    },
    sectionHeader: {
        fontSize: theme.palette?.text?.fontSize15 || '15px',
        marginBottom: 14,
        color: theme.palette.sectionHeaderTextColor,
        letterSpacing: '0.42px',
        fontWeight: '700',
        // tab desktop
        lineHeight: '1.43',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            lineHeight: '32px',
            flex: '0 0 auto',
            fontSize: theme.typography?.fontSize || '16px',
            color: theme.palette.white,
            textTransform: 'uppercase',
            margin: '0 7px 0 5px',
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            fontSize: theme.palette?.text?.fontSize18 || '18px',
            margin: '0 16px 0 7px',
        },
    },
    fagfInputContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            alignItems: 'flex-end',
            flexWrap: 'nowrap',
            width: 'auto',
        },
        [theme.breakpoints.down(767)]: {
            '& input, & select': {
                color: '#757575',
            },
            '& input::placeholder': {
                color: '#222222',
            },
            '& select:focus': {
                color: '#757575',
            },
        },
    },
    zipContainer: {
        flex: '0 0 50%',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            flex: '1 0 110px',
            marginRight: '8px',
            minWidth: '130px',
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            flex: '0 0 160px',
            marginRight: '16px',
        },
        '& input': {
            opacity: '1 !important',
            '&:focus-visible': {
                outline: '-webkit-focus-ring-color auto 1px',
                borderRadius: '3px',
                // outline: `1px solid ${theme.palette.gfBgColor || 'rgba(174,174,174,0.6)' }`, // for firefox, 2 more use cases below

            },
        },
    },
    occasionContainer: {
        flex: '0 0 50%',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            flex: '0 0 160px',
        },
    },
    dateContainer: {
        flex: '0 1 66.666667%',
        [theme.breakpoints.up(1025)]: {
            '& select': {
                paddingRight: '28px !important',
            },
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            flex: '1 0 150px',
            marginRight: '8px',
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            flex: '0 1 187px',
            marginRight: '16px',
            minWidth: '167px',
        },
    },
    formControlWidth: {
        width: 'calc(100% - 13px)',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            width: '100%',
        },
    },
    btnContainer: {
        margin: '0 auto',
        flex: '0 1 33.333333%',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            flex: '0 1 160px',
            minWidth: '133px',
        },
    },
    occasion: {
        color: theme.palette.gfMobileLabelTextColor,
        letterSpacing: '0.42px',
    },
    label: {
        color: theme.palette.gfMobileLabelTextColor,
        letterSpacing: '0.42px',
        fontSize: theme.typography?.fontSize || '16px',
        '&$labelFocus': {
            color: theme.palette.gfMobileLabelTextColor,
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                color: theme.palette.white,
            },
        },
        [theme.breakpoints.down(325)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
        // tab and desktop
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            color: theme.palette.white,
            fontSize: theme.palette?.text?.fontSize12 || '12px',
            fontWeight: 300,
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
    },
    labelFocus: {},
    shrink: {
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            transform: 'translate(0, 1.5px) scale(1)',
        },
    },
    button: {
        textTransform: 'uppercase',
        letterSpacing: '0.026rem',
        padding: '5px 9px',
        marginTop: '10px',
        fontSize: theme.typography?.fontSize || '16px',
        '&:hover': {
            backgroundColor: theme.palette.primaryButton,
        },
        '&:focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px',
            borderRadius: '3px',
        },
        [theme.breakpoints.down(325)]: {
            fontSize: theme.palette?.text?.fontSize15 || '15px',
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
            textTransform: 'uppercase',
            color: '#000',
            fontWeight: 700,
        },
    },
    search: {
        display: 'block',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            display: 'none',
        },
    },
    findAGiftNow: {
        display: 'none',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            display: 'block',
            fontSize: '12.5px',
        },
    },
    nativeSelect: {
        '&:focus': {
            backgroundColor: 'rgba(0,0,0,0)',
        },
    },
    select: {
        color: theme.palette.gfMobileSelectOptionTextColor,
        letterSpacing: '0.42px',
        borderBottom: theme.palette.gfMobileInputBorderBottom,
        fontSize: theme.typography?.fontSize || '16px',
        '&:focus-visible': {
            backgroundColor: 'rgba(0,0,0,0)',
            color: theme.palette.bgHeader, // TO DO: Need palette primary
            outline: '-webkit-focus-ring-color auto 1px',
            borderRadius: '3px',
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            height: '2rem',
            backgroundColor: theme.palette.white,
            padding: '0 22px 0 10px',
            fontSize: theme.palette?.text?.fontSize12 || '12px',
            border: theme.palette.gfTabDesktopInputBorder,
            borderRadius: '3px',
            '&:focus': {
                borderRadius: '3px',
                backgroundColor: theme.palette.white,
            },
        },
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabLandscape)]: {
            fontSize: theme.palette?.text?.fontSize14 || '14px',
        },
    },
    inputMarginTop: {
        'label + &': {
            [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
                marginTop: '22px',
            },
        },
    },
    arrowIcon: {
        fontSize: '33px',
        top: 'calc(50% - 17px)',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            fontSize: '30px',
            top: 'calc(50% - 15px)',
        },
    },
    calIcon: {
        fontSize: theme.palette?.text?.fontSize20 || '20px',
        [theme.breakpoints.up(CUSTOM_BREAKPOINTS.tabPortrait)]: {
            right: '5px',
            top: '10px',
            fontSize: theme.typography?.fontSize || '16px',
        },
    },
    // C&T Gift Finder - IF NO LONGER USED REMOVE
    ctGfForm: {
        margin: 0,
        [theme.breakpoints.down(767)]: {
            margin: '0px 15px 15px',
        },
    },
    ctGfContainer: {
        width: '100%',
        backgroundColor: '#FCF1F2',
        borderRadius: '5px',
        padding: '24px 24px 24px 24px',
    },
    ctGfInnerContainer: {
        maxWidth: '1400px',
        margin: '0 auto',
        width: '100%',
        height: '114px',
        padding: '0 15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down(767)]: {
            padding: '0',
            height: 'auto',
            flexWrap: 'wrap',
        },
    },
    iconAndHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '40px',
        [theme.breakpoints.down(1025)]: {
            marginRight: '15px',
        },
        [theme.breakpoints.down(767)]: {
            width: '100%',
            alignItems: 'stretch',
            justifyContent: 'center',
            marginRight: '0px',
            marginBottom: '12px',
        },
    },
    ctIcon: {
        width: '56px',
        height: '56px',
        overflow: 'hidden',
        marginRight: '40px',
        [theme.breakpoints.down(1025)]: {
            width: '36px',
            height: '36px',
            marginRight: '15px',
        },
        [theme.breakpoints.down(767)]: {
            marginRight: '10px',
        },
        '& img': {
            display: 'block',
            maxWidth: '100%',
        },
    },
    ctHeader: {
        width: 'auto',
        margin: 0,
        fontFamily: 'PlayfairDisplayBold',
        fontWeight: 'normal',
        fontSize: '28px',
        lineHeight: '30px',
        color: '#C81842',
        letterSpacing: '0px',
        [theme.breakpoints.down(321)]: {
            fontSize: '26px',
        },
    },
    ctFieldContainer: {
        flex: '1 1 auto',
        [theme.breakpoints.down(767)]: {
            //
        },
    },
    ctFieldInnerContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexWrap: 'wrap',
    },
    ctInputContainer: {
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%',
        marginBottom: '10px',
        '&:nth-child(3)': {
            marginBottom: '0px',
        },
    },
    ctSelect: {
        fontSize: theme.palette?.text?.fontSize18 || '18px',
        marginTop: '20px !important',
        background: '#FFF !important',
        border: 'none',
        borderRadius: '4px',
        boxShadow: 'none',
        '& input, & select': {
            height: '46px',
            padding: '0 12px 2px 12px',
            fontSize: theme.typography?.fontSize || '16px',
            color: '#000',
            borderRadius: '4px',
            border: '1px solid transparent',
            boxShadow: 'none',
            outline: 0,
            '&:focus, &:active': {
                borderRadius: '4px',
                border: '1px solid #262626',
                boxShadow: 'none',
                outline: 0,
            },
        },
        '& svg': {
            color: '#222222',
            width: '40px',
            height: '40px',
            top: 'calc(50% - 20px)',
        },
        '&.Mui-focused': {
            background: 'transparent',
        },
    },
    ctInputLabel: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        color: '#595959 !important',
        [theme.breakpoints.down(1025)]: {
            fontSize: theme.typography?.fontSize || '16px',
        },
    },
    ctInputLabelArriveMobile: {
        width: 'auto',
    },
    ctInputLabelZipMobile: {
        color: '#595959 !important',
        lineHeight: '16px',
        letterSpacing: '0.4px',
    },
    ctBorder: {
        borderBottom: '1px solid #AAAAAA',
    },
    ctRightBorder: {
        borderRight: '1px solid #AAAAAA',
    },
    ctDateIcon: {
        display: 'none',
    },
    ctButtonContainer: {
        flexGrow: '0',
        maxWidth: '100%',
        flexBasis: '100%',
        marginTop: '24px',
    },
    noDisplayDateFieldMarginBottom: {
        marginBottom: '6px',
    },
    ctButton: {
        fontFamily: 'LatoMedium',
        fontSize: theme.palette?.text?.fontSize18 || '18px',
        lineHeight: '18px',
        letterSpacing: '0.04em',
        backgroundColor: '#C81842',
        color: '#FFF',
        fontWeight: 700,
        height: '50px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        borderRadius: 3,
        padding: 0,
    },
    giftFinderHorizontal: {
        margin: '0 auto',
        justifyContent: 'center',
        display: 'flex',
        paddingBottom: '24px',
        '@media screen and (max-width: 767px)': {
            padding: '0px 24px 30px',
            margin: '0px 12px',
        },
    },
    fastLineGiftFinder: {
        '@media screen and (max-width: 767px)': {
            '& $ctGfForm': {
                margin: '0px 15px 0px',
            },
            '& $giftFinderHorizontal': {
                margin: '0px 12px 15px',
            },
        },
    },
});

const isCandaZip = (zipcode) => new RegExp(/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/).test(zipcode);

class GraphqlFindAGiftFast extends Component {
    constructor(props) {
        super(props);
        const { dateOptions, userSubmittedZip } = this.props;
        this.state = {
            form: {
                zip: userSubmittedZip,
                date: dateOptions?.[0],
                collectionNumber: '',
            },
            hasError: false,
            onSubmitCheck: false,
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    // triggerTrack = () => {
    //     const { data, ffIsGiftFinderTestEnabled, trackEvent } = this.props;
    //     if (ffIsGiftFinderTestEnabled) {
    //         trackEvent({
    //             eventCategory: data?.a_b_testing?.tracking_event_category || 'eventCategory NOT SET IN CMS',
    //             eventAction: data?.a_b_testing?.tracking_event_action || 'eventAction NOT SET IN CMS',
    //             eventLabel: data?.a_b_testing?.tracking_event_label || 'eventLabel NOT SET IN CMS',
    //             nonInteraction: true,
    //         });
    //     }
    // }

    // componentWillUnmount() {
    //     window.removeEventListener('pageViewFired', this.triggerTrack);
    // }

    componentDidMount() {
        // set the occasion feild to first select feild
        const {
            data, setOccasion, callCustomTrackingEvent, salesforceResponse, setShowSkeleton,
        } = this.props;
        const defaultOccasion = data?.gift_finder_blocks?.[1]?.occasion?.occasion_option_group?.[0]?.option_name || 'Birthday';
        const defaultCodeOccasion = data?.gift_finder_blocks?.[1]?.occasion?.occasion_option_group?.[0]?.code || '3';
        setOccasion(defaultOccasion, defaultCodeOccasion, '');

        window.addEventListener('pageViewFired', this.triggerTrack);

        if (salesforceResponse?.campaign) {
            callCustomTrackingEvent({
                action: 'Impression', salesforceResponse, page: { type: 'home' }, eventSubType: 'campaign-tracking',
            });
        }
        setShowSkeleton(false);
    }

    componentDidCatch(jsError, reactInfo) {
        mbpLogger.logError({
            message: `Unhandled Error in GraphqlFindAGiftFast \n jsError = ${jsError} \n reactInfo = ${reactInfo}`,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
        });
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        const {
            form: {  zip },
        } = this.state;
        const {
            zipcodeIsValid, trackEvent, data,
        } = this.props;
        if (zipcodeIsValid !== prevProps.zipcodeIsValid && zipcodeIsValid && data?._content_type_uid === 'gift_finder' && zip) {
            trackEvent({
                eventCategory: 'Homepage',
                eventAction: 'Gift Finder',
                eventLabel: `Zipcode|${zip}`,
            });
        }
    }

    handleZip = () => {
        const {
            form: { zip },
        } = this.state;
        const {
            validateZip,
            clearZip,
            validatedZip,
            zipcodeIsValid,
            resetZipcodeValidity,
            countryName,
        } = this.props;
        const country = countryName.toLowerCase();
        if ((zip.length >= 5 && country !== 'canada') || (zip.length >= 7 && country === 'canada')) {
            // send zip to redux store, then check if valid
            validateZip(zip);
        } else if (zip.length < 5 && validatedZip !== '') {
            clearZip();
        }
        if (zip.length < 5 && zipcodeIsValid !== null) {
            resetZipcodeValidity();
        }
    };

    convertZipCode = (zipcode, preState) => {
        // handle zipcode conversion for canda
        if (preState.length < zipcode.length
            && zipcode.length >= 6
            && isCandaZip(zipcode)) {
            const zipSplit = zipcode.replace(/\s/gi, '').toUpperCase().split('');
            zipSplit.splice(3, 0, ' ');
            return zipSplit.join('');
        }
        return zipcode;
    }

    handleChange = (name) => (event) => {
        const { form } = this.state;
        // only invoke callback if we are updating zip input
        const textBoxVal = event.target.value.trim();
        if (name === 'zip' && textBoxVal.length <= 7) {
            const zipCode = this.convertZipCode(textBoxVal, form.zip);
            // handle update for local zip input
            this.setState(
                {
                    form: {
                        ...form,
                        [name]: zipCode,
                    },
                    onSubmitCheck: false,
                },
                this.handleZip,
            );
        } else if (name === 'date') {
            // handle update for local date select
            this.setState({
                form: {
                    ...form,
                    [name]: textBoxVal,
                },
            });
        }
        return false;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {
            data,
            occasion,
            history,
            validatedZip,
            setUserSubmittedZip,
            trackEvent,
            callCustomTrackingEvent,
            ffIsGiftFinderTestEnabled,
            ldClient,
        } = this.props;
        const {
            form: { zip, date },
            onSubmitCheck,
        } = this.state;

        // get the occasion array and find the option_name(occasion) that matches the occasion the user selected
        const occasionFound = data.gift_finder_blocks?.[1]?.occasion?.occasion_option_group?.find(
            (d) => d.option_name === occasion?.name,
        );

        // if user tries to submit with no zip or less then 5 digits show error
        // else if zip is 5 and validatedZip is not empty route user to collection
        if (zip.length < 5 && onSubmitCheck === false) {
            this.setState({
                onSubmitCheck: true,
            });
        } else if (
            zip.length >= 5
            && validatedZip !== ''
            && validatedZip === zip
        ) {
            if (ffIsGiftFinderTestEnabled) {
                ldClient.track('Gift Finder Button Clicked');
            }

            const trackData = {
                ea: data?.a_b_testing?.submission_tacking_event_action || data?.a_b_testing?.tracking_event_action || data?.tracking_event_action || 'Homepage',
                ec: data?.a_b_testing?.submission_tacking_event_category || data?.a_b_testing?.tracking_event_category || data?.tracking_event_category || 'Gift Finder',
                el: data?.a_b_testing?.submission_tacking_event_label ? data?.a_b_testing?.submission_tacking_event_label.replace('{{Zip}}', zip).replace('{{Occasion}}', occasionFound?.option_name) : '',
            };
            // route user to collection page
            setUserSubmittedZip(zip.trimEnd());
            trackEvent({
                eventCategory: trackData.ec,
                eventAction: trackData.ea,
                eventLabel: trackData?.el || `${zip}|${occasion.name}${date ? `|${date}` : ''} }`,
                giftOccasion: occasion?.name,
                giftDate: date,
                giftZipcode: zip,
            });
            callCustomTrackingEvent({
                action: 'customer-input', occasionCode: occasionFound?.option_name?.toLowerCase(), page: { type: 'home' }, zipCode: zip, eventSubType: 'customer-input-find-gift-fast',
            });
            if (occasionFound) {
                let url = occasionFound?.option_value || '';
                let searchParam = '';
                if (occasionFound?.option_value?.indexOf?.('?') >= 0) {
                    const [pathUrl, pathParam] = occasionFound?.option_value?.split?.('?') || [];
                    url = pathUrl;
                    searchParam = pathParam;
                }
                const historyData = {
                    pathname: url || occasionFound?.option_value,
                    state: {
                        giftOccasion: occasion?.name,
                        giftDate: date,
                        giftZipcode: zip,
                    },
                };
                if (searchParam) {
                    historyData.search = searchParam;
                }
                history.push(historyData);
            }
        }
    };

    setSelectedOccasion = (ocassionName) => {
        const { data, setOccasion } = this.props;
        const occasionOptionGroup = data.gift_finder_blocks?.[1]?.occasion?.occasion_option_group || [];
        const currentOccasion = occasionOptionGroup.filter((occ) => occ.option_name === ocassionName);
        setOccasion(ocassionName, currentOccasion?.[0]?.code);
    }

    render() {
        const {
            form: { date, zip },
            hasError,
            onSubmitCheck,
        } = this.state;
        const {
            classes,
            data,
            occasion,
            dateOptions,
            zipcodeIsValid,
            userSubmittedZip,
            giftFinderV2,
            ffIsGiftFinderEnabled,
            ssrDeviceType,
            dateFieldABTest,
            brand,
            profileGateWayGiftFinder,
            ffIsGFSuggestionsEnabled,
            flags,
            findPerfectGiftEnabled,
            isPlaEnabled,
            hasHomepageGridStructure,
        } = this.props;
        const giftFinderPresentation = data?.presentation;
        const sectionTitle = data?.title_name || '';
        const showSectionTitleIcon = data?.show_title_icon;
        const giftFinderBgColor = data?.background_color || '';
        const giftFinderBgColorOpacity = data?.background_color_opacity || 1;
        const convertedGiftFinderBgColor = convertAlphaDecToHex(giftFinderBgColor, giftFinderBgColorOpacity);
        const giftFinderBodyTextColor = data?.text_color || '';
        const productData = data?.product_recommendations || [];
        const inputBorderColor = data.input_border_color || 'transparent';
        const occasion_option_group = data?.gift_finder_blocks?.[1]?.occasion?.occasion_option_group || [];
        const occasionTitle = data?.gift_finder_blocks?.[1]?.occasion?.title || '';
        const zipLabel = data?.gift_finder_blocks?.[0]?.delivery_zip_code?.label || '';
        const displayOccasionField = data?.gift_finder_blocks?.some((block) => block?.occasion);
        const deliveryDateTitle = data?.gift_finder_blocks?.[2]?.delivery_date?.label || '';
        const displayDateField = data?.gift_finder_blocks?.some((block) => block?.delivery_date);
        const giftFinderSuggestionEnable = ffIsGFSuggestionsEnabled && flags?.whichGiftFinderSuggestion === 'variant' && data?.has_recommendations;
        const buttonStyle = {
            backgroundColor: data?.button_background_color || 'rgb(0, 133, 77)',
            color: data?.button_text_color || '',
            width: data?.cta_button_width && ssrDeviceType === 'mobile' && dateFieldABTest !== 'Control' ? `${data?.cta_button_width}px` : '',
        };
        const dateFieldBrand = brand?.domain === 'berries' || brand?.domain === '1800flowers';
        const hideGuidedNav = !ffIsGiftFinderEnabled || hasError;
        let top;
        if (hasHomepageGridStructure) {
            if (brand?.domain === 'berries' || brand?.domain === 'fruitbouquets') {
                top = 'undefined';
            }
            if (brand?.domain === '1800flowers') {
                top = '-2rem';
                const cardEl = document.querySelector('.testCard');
                if (cardEl) {
                    cardEl.style.setProperty('margin-bottom', '-2rem', 'important');
                }
            }
        } else {
            top = undefined;
        }

        const rootTopCss = top;

        if (hideGuidedNav) {
            return null;
        }
        const ldGiftFinderVariant = flags?.whichGiftFinderVariant?.variant?.toLowerCase();
        if (data?._content_type_uid === 'find_the_perfect_gift' && findPerfectGiftEnabled) {
            return (
                <PerfectGiftFastContainer
                    blockData={data}
                    handleChange={this.handleChange}
                    onSubmitCheck={onSubmitCheck}
                    zip={zip}
                />
            );
        }
        if (profileGateWayGiftFinder && data?._content_type_uid === 'gift_finder_gateway') {
            return (
                <ComponentErrorBoundary module="GiftFinder" function="GraphqlFindAGiftFast">
                    <GiftFinderProfileContainer
                        blockData={data}
                        userSubmittedZip={userSubmittedZip}
                        zip={zip}
                        handleChange={this.handleChange}
                        onSubmitCheck={onSubmitCheck}
                        zipcodeIsValid={zipcodeIsValid}
                        occasion={occasion}
                        ldGiftFinderVariant={ldGiftFinderVariant}
                    />
                </ComponentErrorBoundary>
            );
        }
        if ((data?._content_type_uid === 'gift_finder_universal' && ldGiftFinderVariant === 'variant c') || isPlaEnabled) {
            return (
                <ComponentErrorBoundary module="GiftFinderUniversal" function="GiftFinderUniversal">
                    <GiftFinderUniversal
                        blockData={data}
                        userSubmittedZip={userSubmittedZip}
                        zip={zip}
                        handleChange={this.handleChange}
                        onSubmitCheck={onSubmitCheck}
                        zipcodeIsValid={zipcodeIsValid}
                        occasion={occasion}
                        isPlaEnabled={isPlaEnabled}
                    />
                </ComponentErrorBoundary>
            );
        }
        // Feature flag is true and cms checkbox for gift finder render
        if (giftFinderV2 && data?.code_theory_gift_finder) {
            return (
                <div className={giftFinderSuggestionEnable ? classes.fastLineGiftFinder : ''}>
                    <form className={classes.ctGfForm} onSubmit={this.handleSubmit}>
                        <div className={classes.ctGfContainer} style={{ backgroundColor: giftFinderBgColor }}>
                            <div className={classes.ctGfInnerContainer}>
                                <div className={classes.iconAndHeaderContainer}>
                                    <h3 className={classes.ctHeader} style={{ color: giftFinderBodyTextColor }}>
                                        {ReactHTMLParser(sectionTitle?.replace?.('\\n', '<br/>'))}
                                    </h3>
                                </div>
                                <div className={classes.ctFieldContainer}>
                                    <div className={classes.ctFieldInnerContainer}>
                                        <div className={classes.ctInputContainer}>
                                            <GiftFindZipCodeBlock
                                                zipLabel={zipLabel}
                                                currentInputLength={zip.length}
                                                handleChange={this.handleChange}
                                                onSubmitCheck={onSubmitCheck}
                                                zipcodeIsValid={zipcodeIsValid}
                                                userSubmittedZip={userSubmittedZip}
                                                value={zip}
                                                giftFinderBodyTextColor={giftFinderBodyTextColor}
                                                giftFinderCodeTheoryView
                                                giftFinderFieldStyle={classes}
                                                inputBorderColor={inputBorderColor}
                                                brandDomain={brand?.domain}
                                            />
                                        </div>
                                        <div className={`${classes.ctInputContainer} ${!displayDateField && classes.noDisplayDateFieldMarginBottom}`}>
                                            <FormControl fullWidth>
                                                <InputLabel
                                                    shrink
                                                    htmlFor="occasion-native-label-placeholder"
                                                    className={classes.ctInputLabel}
                                                    style={{ color: giftFinderBodyTextColor }}
                                                >
                                                    {occasionTitle}
                                                </InputLabel>
                                                <NativeSelect
                                                    disableUnderline
                                                    value={occasion?.name}
                                                    onChange={(e) => {
                                                        this.setSelectedOccasion(e.target.value);
                                                    }}
                                                    className={classes.ctSelect}
                                                    style={{ border: `1px solid ${inputBorderColor}` }}
                                                    IconComponent={ArrowDropDownIcon}
                                                    input={(
                                                        <Input
                                                            name="occasion"
                                                            id="occasion-native-label-placeholder"
                                                            data-test="hp-occasion-native-label-placeholder"
                                                        />
                                                    )}
                                                >
                                                    {occasion_option_group?.map((d) => (
                                                        <option
                                                            value={d.option_name}
                                                            key={d.option_name}
                                                        >
                                                            {d.option_name}
                                                        </option>
                                                    ))}
                                                </NativeSelect>
                                            </FormControl>
                                        </div>
                                        {displayDateField
                                        && (
                                            <div className={classes.ctInputContainer}>
                                                <FormControl fullWidth>
                                                    <InputLabel
                                                        shrink
                                                        htmlFor="arrive-native-label-placeholder"
                                                        className={`${classes.ctInputLabel} ${classes.ctInputLabelArriveMobile} `}
                                                        style={{ color: giftFinderBodyTextColor }}
                                                    >
                                                        {deliveryDateTitle}
                                                    </InputLabel>
                                                    <NativeSelect
                                                        disableUnderline
                                                        value={date}
                                                        onChange={this.handleChange('date')}
                                                        className={classes.ctSelect}
                                                        style={{ border: `1px solid ${inputBorderColor}` }}
                                                        IconComponent={ArrowDropDownIcon}
                                                        input={(
                                                            <Input
                                                                name="age"
                                                                id="arrive-native-label-placeholder"
                                                                data-test="hp-arrive-native-label-placeholder"
                                                            />
                                                        )}
                                                    >
                                                        {dateOptions.map(
                                                            (d, idx) => (
                                                                <option
                                                                    value={d}
                                                                    key={`date${idx + 1}`}
                                                                >
                                                                    {d}
                                                                </option>
                                                            ),
                                                        )}
                                                    </NativeSelect>
                                                </FormControl>
                                            </div>
                                        )}
                                        <div className={classes.ctButtonContainer}>
                                            <button
                                                id="fagf-btn"
                                                data-testid="fagf-btn"
                                                className={classes.ctButton}
                                                type="submit"
                                                style={buttonStyle}
                                            >
                                                {data?.cta_copy || 'Search'}
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    {giftFinderSuggestionEnable && (
                        <div
                            className={classes.giftFinderHorizontal}
                            style={{
                                backgroundColor: productData?.styling?.module_background_color?.color,
                            }}
                        >
                            <GiftFinderProductContainer productData={productData} />
                        </div>
                    )}
                </div>
            );
        }
        return (
            <Card className={`${classes.root} testCard`} id={giftFinderPresentation} style={{ backgroundColor: convertedGiftFinderBgColor, top: rootTopCss }} uniqueId="giftFinder">
                <CardContent className={classes.cardContentPadding}>
                    <form onSubmit={this.handleSubmit}>
                        <div className={classes.fagfContainer}>
                            <div className={classes.sectionheaderContainer}>
                                {showSectionTitleIcon && (
                                    <div className={classes.fagfSVG}>
                                        <svg
                                            viewBox="0 0 27 27"
                                            preserveAspectRatio="xMidYMid meet"
                                            focusable="false"
                                            style={{
                                                pointerEvents: 'none',
                                                display: 'block',
                                            }}
                                        >
                                            <g>
                                                <path
                                                    fill="#FFF"
                                                    style={{ fill: giftFinderBodyTextColor }}
                                                    d="M22,12V20A2,2 0 0,1 20,22H4A2,2 0 0,1 2,20V12A1,1 0 0,1 1,11V8A2,2 0 0,1 3,6H6.17C6.06,5.69 6,5.35 6,5A3,3 0 0,1 9,2C10,2 10.88,2.5 11.43,3.24V3.23L12,4L12.57,3.23V3.24C13.12,2.5 14,2 15,2A3,3 0 0,1 18,5C18,5.35 17.94,5.69 17.83,6H21A2,2 0 0,1 23,8V11A1,1 0 0,1 22,12M4,20H11V12H4V20M20,20V12H13V20H20M9,4A1,1 0 0,0 8,5A1,1 0 0,0 9,6A1,1 0 0,0 10,5A1,1 0 0,0 9,4M15,4A1,1 0 0,0 14,5A1,1 0 0,0 15,6A1,1 0 0,0 16,5A1,1 0 0,0 15,4M3,8V10H11V8H3M13,8V10H21V8H13Z"
                                                />
                                            </g>
                                        </svg>
                                    </div>
                                )}
                                <div
                                    className={classes.sectionHeader}
                                    style={{ color: giftFinderBodyTextColor }}
                                >
                                    {sectionTitle}
                                </div>
                            </div>
                            <div className={classes.fagfInputContainer}>
                                <div className={classes.zipContainer}>
                                    <GiftFindZipCodeBlock
                                        zipLabel={zipLabel}
                                        currentInputLength={zip.length}
                                        handleChange={this.handleChange}
                                        onSubmitCheck={onSubmitCheck}
                                        zipcodeIsValid={zipcodeIsValid}
                                        userSubmittedZip={userSubmittedZip}
                                        value={zip}
                                        giftFinderBodyTextColor={giftFinderBodyTextColor}
                                        brandDomain={brand?.domain}
                                    />
                                </div>
                                {displayOccasionField && (
                                    <div className={classes.zipContainer}>
                                        <FormControl fullWidth>
                                            <InputLabel
                                                shrink
                                                htmlFor="occasion-native-label-placeholder"
                                                classes={{
                                                    root: `${classes.label}`,
                                                    shrink: classes.shrink,
                                                    focused: classes.labelFocus,
                                                }}
                                                style={{ color: giftFinderBodyTextColor }}
                                            >
                                                {occasionTitle}
                                            </InputLabel>
                                            <NativeSelect
                                                disableUnderline
                                                value={occasion?.name}
                                                onChange={(e) => {
                                                    this.setSelectedOccasion(e.target.value);
                                                }}
                                                classes={{
                                                    root: classes.selectMarginTop,
                                                    select: classes.select,
                                                    icon: classes.arrowIcon,
                                                }}
                                                input={(
                                                    <Input
                                                        name="occasion"
                                                        id="occasion-native-label-placeholder"
                                                        data-test="4810-hp-occasion-native-label-placeholder"
                                                        classes={{
                                                            root: classes.inputMarginTop,
                                                        }}
                                                    />
                                                )}
                                            >
                                                {occasion_option_group?.map((d) => (
                                                    <option
                                                        value={d.option_name}
                                                        key={d.option_name}
                                                    >
                                                        {d.option_name}
                                                    </option>
                                                ))}
                                            </NativeSelect>
                                        </FormControl>
                                    </div>
                                )}
                                {displayDateField && (!dateFieldBrand || (dateFieldABTest !== 'Variant' && dateFieldBrand)) && (
                                    <div className={classes.dateContainer}>
                                        <FormControl
                                            fullWidth
                                            classes={{
                                                fullWidth: classes.formControlWidth,
                                            }}
                                        >
                                            <InputLabel
                                                shrink
                                                htmlFor="arrive-native-label-placeholder"
                                                classes={{
                                                    root: classes.label,
                                                    shrink: classes.shrink,
                                                    focused: classes.labelFocus,
                                                }}
                                                style={{ color: giftFinderBodyTextColor }}
                                            >
                                                {deliveryDateTitle}
                                            </InputLabel>
                                            <NativeSelect
                                                disableUnderline
                                                value={date}
                                                onChange={this.handleChange('date')}
                                                IconComponent={Calendar}
                                                classes={{
                                                    select: classes.select,
                                                    icon: classes.calIcon,
                                                }}
                                                input={(
                                                    <Input
                                                        name="age"
                                                        id="arrive-native-label-placeholder"
                                                        data-test="hp-arrive-native-label-placeholder"
                                                        classes={{
                                                            root:
                                                                classes.inputMarginTop,
                                                        }}
                                                    />
                                                )}
                                            >
                                                {dateOptions.map(
                                                    (d, idx) => (
                                                        <option
                                                            value={d}
                                                            key={`date${idx + 1}`}
                                                        >
                                                            {d}
                                                        </option>
                                                    ),
                                                )}
                                            </NativeSelect>
                                        </FormControl>
                                    </div>
                                )}
                                <div className={classes.btnContainer}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        id="fagf-btn"
                                        data-testid="fagf-btn"
                                        fullWidth
                                        className={classes.button}
                                        type="submit"
                                        style={buttonStyle}
                                    >
                                        <span className={classes.search}>
                                            {data?.cta_copy || 'Search'}
                                        </span>
                                        <span
                                            className={classes.findAGiftNow}
                                            id="find-a-gift-now-text"
                                            data-test="hp-find-a-gift-now-text"
                                            name="findAGiftNowText"
                                        >
                                            {data?.cta_copy || 'Find A Gift Now'}
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </CardContent>
                {giftFinderSuggestionEnable && (
                    <div
                        className={classes.giftFinderHorizontal}
                        style={{
                            backgroundColor: productData?.styling?.module_background_color?.color,
                        }}
                    >
                        <GiftFinderProductContainer productData={productData} />
                    </div>
                )}
            </Card>
        );
    }
}

const mapStateToProps = (state) => ({
    validatedZip: getValidatedZipcode(state),
    occasion: getProductFilterOccasion(state),
    zipcodeIsValid: getIsProductFilterZipcodeValid(state),
    userSubmittedZip: getUserSubmittedProductFilterZipcode(state),
    ffIsGiftFinderEnabled: getFeatureFlag('is-gift-finder-enabled')(state),
    giftFinderV2: getFeatureFlag('is-gift-finder-v2-enabled')(state),
    ffIsGiftFinderTestEnabled: getFeatureFlag('is-gift-finder-test-enabled')(state),
    ffIsGFSuggestionsEnabled: getFeatureFlag('is-gift-finder-suggestions-enabled')(state),
    ssrDeviceType: getSSRDeviceType(state),
    dateFieldABTest: getActiveABTest('display_date_field')(state),
    brand: getBrand(state),
    countryName: selectors.country(state),
    profileGateWayGiftFinder: getFeatureFlag('is-project-gateway-gift-finder-enabled')(state),
    findPerfectGiftEnabled: getFeatureFlag('is-find-perfect-gift-enable')(state),
    hasHomepageGridStructure: getFeatureFlag('has-homepage-grid-structure')(state),
});

const mapDispatchToProps = (dispatch) => ({
    validateZip: bindActionCreators(validateProductFilterZipcode, dispatch),
    clearZip: bindActionCreators(clearValidatedZipcode, dispatch),
    setOccasion: bindActionCreators(setProductFilterOccasion, dispatch),
    resetZipcodeValidity: bindActionCreators(
        resetProductFilterZipcodeValidity,
        dispatch,
    ),
    setUserSubmittedZip: bindActionCreators(
        setUserSubmittedProductFilterZipcode,
        dispatch,
    ),
    trackEvent: bindActionCreators(tagManagerActions.trackEvent, dispatch),
    callCustomTrackingEvent: bindActionCreators(emitCustomTrackingEvent, dispatch),
});

GraphqlFindAGiftFast.propTypes = {
    history: object.isRequired,
    data: object.isRequired,
    classes: object.isRequired,
    validateZip: func.isRequired,
    clearZip: func.isRequired,
    setOccasion: func.isRequired,
    validatedZip: string.isRequired,
    occasion: shape({
        code: string,
        name: string,
    }).isRequired,
    dateOptions: array.isRequired,
    zipcodeIsValid: bool, // Do not use isRequired because prop spends most time as null.
    resetZipcodeValidity: func.isRequired,
    setUserSubmittedZip: func.isRequired,
    userSubmittedZip: string.isRequired,
    trackEvent: func.isRequired,
    giftFinderV2: bool.isRequired,
    ffIsGiftFinderEnabled: bool.isRequired,
    ffIsGFSuggestionsEnabled: bool.isRequired,
    ffIsGiftFinderTestEnabled: bool.isRequired,
    ldClient: object,
    ssrDeviceType: string.isRequired,
    brand: shape({
        'domain-name': string,
    }).isRequired,
    dateFieldABTest: string,
    countryName: string.isRequired,
    profileGateWayGiftFinder: bool.isRequired,
    callCustomTrackingEvent: func.isRequired,
    salesforceResponse: object,
    setShowSkeleton: func,
    flags: object.isRequired,
    findPerfectGiftEnabled: bool.isRequired,
    isPlaEnabled: bool,
    hasHomepageGridStructure: bool.isRequired,
};

GraphqlFindAGiftFast.defaultProps = {
    zipcodeIsValid: null,
    dateFieldABTest: '',
    ldClient: {},
    salesforceResponse: {},
    setShowSkeleton: noop,
    isPlaEnabled: false,
};

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withStyles(styles),
    withLDConsumer(),
);

export default enhance(GraphqlFindAGiftFast);
