/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {
    object, shape, string, arrayOf, bool, array,
} from 'prop-types';
import { useUIDSeed } from 'react-uid';
import { compose } from 'recompose';
import { connect, useSelector } from 'react-redux';
import ImocBanner from './ImocBanner';
import GraphqlDealOfWeek from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlDealOfWeek/GraphqlDealOfWeek';
import GraphqlSeoHeadTags from '../../../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import GraphqlIcBanner from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlIcBanner';
import SimpleProduct from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/GraphqlSimpleProduct';
import GraphqlDisclaimer from '../../../GraphqlCategoryPage/Partials/GraphqlDisclaimer/GraphqlDisclaimer';
import RailMenuTabletContainer from '../../../GraphqlCategoryPage/Partials/RailMenuTabletContainer/RailMenuTabletContainer';
import SubCollectionHeader from '../TemplateCollectionSubnavBanner/Partials/SubCollectionHeader/SubCollectionHeader';
import { getSSRDeviceType } from '../../../../../../state/ducks/App/App-Selectors';
import GraphqlBreadcrumbs from '../../../GraphqlCommonComponents/GraphqlBreadcrumbs/GraphqlBreadcrumbs';
import GraphqlSubnav from '../../../GraphqlCategoryPage/Partials/GraphqlCatHeaderContainer/Partials/GraphqlSubnav/GraphqlSubnav';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import GraphqlLeftMenuContainer from '../../../GraphqlCategoryPage/Partials/GraphqlLeftMenuContainer/GraphqlLeftMenuContainer';
import { getProductPerRow } from '../../../../../helpers/common/helper';
import FeatureLoader from './FeatureLoader';
// import Features from '../../../UniversalTemplate/Components/Features/Features';

const styles = (theme)  => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downSmall,
            margin: '0 auto',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers.container.maxWidth.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: 1440,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: 1440,
            margin: '0 auto',
        },
    },
    collectionWrapper: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        marginTop: 10,
        [theme.breakpoints.down('600')]: {
            padding: '0 16px 0',
        },
    },
    mainCollection: {
        width: 'calc(100% - 290px)',
        marginLeft: '30px',
        [theme.breakpoints.down('768')]: {
            marginLeft: 15,
        },
        [theme.breakpoints.down('600')]: {
            width: '100%',
            marginTop: 15,
            marginLeft: 0,
        },
    },
    mainCollectionWithoutGnav: {
        width: 'calc(100% - 290px)',
        marginLeft: '30px',
        [theme.breakpoints.down('768')]: {
            marginLeft: 15,
        },
        [theme.breakpoints.down('600')]: {
            width: '100%',
            marginTop: 15,
            marginLeft: 0,
        },
    },
    productRow: {
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        marginRight: '-10px',
        marginLeft: '-10px',
        width: 'auto',
    },
    fourProductsInRow: {
        flex: '0 0 25%',
        marginBottom: '30px',
        maxWidth: '25%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
    },
    fiveProductsInRow: {
        flex: '0 0 20%',
        marginBottom: '30px',
        maxWidth: '20%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
    },
    twoProductsInRow: {
        flex: '0 0 50%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        maxWidth: '50%',
    },
    guidedNav: {
        width: 260,
        [theme.breakpoints.down('600')]: {
            width: '100%',
        },
    },
    threeProductsInRow: {
        flex: '0 0 33.333333%',
        maxWidth: '33.333333%',
        marginBottom: '30px',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
    },
    um0: {
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
        },
    },
    simpleSubNavProduct5Row: {
        boxSizing: 'border-box',
        flex: '0 0 16.66%',
        marginBottom: 10,
        maxWidth: '16.66%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '20%',
            flex: '0 0 20%',
        },
    },
    simpleSubNavProduct4Row: {
        boxSizing: 'border-box',
        flex: '0 0 20%',
        marginBottom: 10,
        maxWidth: '20%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '50%',
            flex: '0 0 50%',
        },
    },
    simpleSubNavProduct3Row: {
        boxSizing: 'border-box',
        flex: '0 0 25%',
        marginBottom: '30px',
        maxWidth: '25%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '33.33%',
            flex: '0 0 33.33%',
        },
    },
    simpleSubNavProduct2Row: {
        boxSizing: 'border-box',
        flex: '0 0 50%',
        marginBottom: '30px',
        maxWidth: '50%',
        position: 'relative',
        width: '100%',
        paddingRight: '10px',
        paddingLeft: '10px',
    },
    subNavContainer: {
        boxSizing: 'border-box',
        width: '100%',
        padding: '10px',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    aboveProductHeroCopy: {
        textAlign: 'center',
        padding: '10px 0 20px 0',
        fontSize: '28px',
        '& > *': {
            margin: 0,
            padding: 0,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '17px',
            fontWeight: '500',
        },
    },
    subNavProductRow: {
        display: 'flex',
        flexWrap: 'wrap',
        width: 'auto',
        boxSizing: 'border-box',
        padding: '20px 10px',
        marginRight: '-10px',
        marginLeft: '-10px',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: 5,
        },
    },
    subCollectionHeader: {
        display: 'flex',
        alignItems: 'stretch',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.down(600)]: {
            border: 'inherit',
        },
        '& p': {
            margin: 0,
            fontSize: '26px',
            [theme.breakpoints.down(600)]: {
                fontSize: '19px',
            },
        },
        '& p:nth-child(2)': {
            fontSize: '60px',
            margin: '7px 0',
        },
    },
    belowProductHeroCopy: {
        textAlign: 'center',
        padding: '20px 0 10px 0',
        fontSize: '28px',
        '& > *': {
            margin: 0,
            padding: 0,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '17px',
        },
    },
    breadcrumbAndFilterContainer: {
        display: 'flex',
        alignItems: 'baseline',
        margin: '10px 0',
        flexWrap: 'wrap',
        [theme.breakpoints.down(599)]: {
            display: 'block',
        },
    },
});

const TemplatePageSales = ({
    classes, templateData, presentationFamily, brand, page, isMobile, ssrDeviceType, guidedNavModalHasBeenActivated, isFacetFiltersCountEnabled, productFacetsBase, isFoodBrand, isFacetFiltersEnabled, filterFlag, productsData,
}) => {
    const featureFlagIsMobileCollectionTabbed = useSelector((state) => getFeatureFlag('is-mobile-collection-tabbed')(state));
    const isGuidedNavWidgetEnabled = useSelector((state) => getFeatureFlag('is-guided-nav-widget-enabled')(state));
    const contentData = templateData?.content?.entries?.[0] || {};
    const pageBlocks = templateData?.content?.entries?.[0]?.page_blocks || [];
    const categoryData = productsData.length > 0 ? productsData : templateData?.category?.products ||  null;
    const headerSubCopy = contentData?.header_sub_copy || null;
    const header = contentData?.header || null;
    const { disclaimer } = pageBlocks?.find((block) => block?.disclaimer) || {};
    const featureSingle = pageBlocks?.find((block) => block?.featured_product) || {};
    const productPerRowDesktopMobile = contentData?.product_layout?.find((block) => (
        isMobile ?  block?.name?.includes('Mobile')
            : block?.name?.includes('Desktop') || block?.name?.includes('Desktop/Tablet'))) || {};
    let seo;
    if (contentData) {
        seo = JSON.parse(JSON.stringify(contentData.seo));
    }
    let firstProduct = [];
    let products = [];
    if (categoryData.length) {
        firstProduct = categoryData[0];
        products = categoryData.slice(1);
    }
    const productData = products;
    const icBannerBlock = [];
    if (pageBlocks?.length > 0) {
        pageBlocks.forEach((block) => {
            if (block?.in_collection_banners?.show_after_product_number) {
                icBannerBlock[block.in_collection_banners.show_after_product_number - 1] = block.in_collection_banners;
            }
        });
    }

    const guideNavReferenceData  = templateData?.content?.entries?.[0]?.guided_nav?.guided_nav_form_reference;
    const guidedNavForm = guideNavReferenceData?.length > 0 ? guideNavReferenceData : templateData?.guidedNavForm?.entries?.[0];
    const isShowGuidedNav = contentData?.guided_nav?.show_guided_nav && productData.length > 0;

    const { sub_nav = {} } = pageBlocks?.find((block) => block?.sub_nav) || {};
    const { sub_navigation = {} } = pageBlocks?.find((block) => block?.sub_navigation) || {};
    const subNavProductCollection = (templateData?.subNavCollection?.products) ? templateData.subNavCollection.products : null;
    const showNoOfProducts = ssrDeviceType === 'mobile' ? sub_nav?.number_of_products_mobile : sub_nav?.number_of_products;
    const subNavProductRender = subNavProductCollection?.slice(0, showNoOfProducts);
    const numOfProducts = sub_nav?.number_of_products || 3;

    const productPerRow = (rowNumber) => {
        switch (rowNumber) {
            case 5:
                return classes.simpleSubNavProduct5Row;
            case 4:
                return classes.simpleSubNavProduct4Row;
            case 2:
                return classes.simpleSubNavProduct2Row;
            default:
                return classes.simpleSubNavProduct3Row;
        }
    };

    const seed = useUIDSeed();
    seo.og = [];
    seo.og.push(
        { property: 'og:title', content: seo.page_title || '' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt14c69648de0bb6e1/5ee3cc56711ec84420520c9a/deal-of-the-week-everyday.jpg' },
        { property: 'og:description', content: seo.meta_description || '' },
        { property: 'og:url', content: `https://www.${brand.domain}.com${page.path || ''}` },
    );

    const  productPerRowWithGuidedNav = (guidedNavForm && isShowGuidedNav)
        ? classes.threeProductsInRow
        : classes.fourProductsInRow;

    const simpleProductClass = Object.keys(productPerRowDesktopMobile).length > 0
        ? getProductPerRow(classes, productPerRowDesktopMobile?.products_per_row)
        : productPerRowWithGuidedNav;
    return (
        <>
            {
                seo?.seo_searchable
                    ? (
                        <GraphqlSeoHeadTags seoData={seo} />
                    ) : null
            }

            <Grid container className={classes.container}>

                {header
                && (
                    <ImocBanner
                        headerSubCopy={headerSubCopy}
                        header={header}
                    />
                )}
                <div className={classes.breadcrumbAndFilterContainer}>
                    <GraphqlBreadcrumbs breadCrumbArray={seo?.breadcrumb} />
                </div>
                {featureSingle && <FeatureLoader uid={featureSingle.featured_product?.reference?.[0]?.uid} contentid="feature_1up_product_one" brand={brand.domain} />}
                {firstProduct && <GraphqlDealOfWeek firstProduct={firstProduct} pageBlocks={pageBlocks} />}

                {Object.keys(sub_navigation).length > 0 && <GraphqlSubnav subnavBlock={sub_navigation} trackEvent={trackEvent} featureFlagIsMobileCollectionTabbed={featureFlagIsMobileCollectionTabbed} />}
                <SubCollectionHeader
                    classes={classes}
                    brand={brand}
                    subNavHeaderPosition={sub_nav?.copy_spot}
                    subNavHeaderCopy={sub_nav?.copy}
                    subNavHeaderFontColor={sub_nav?.copy_font_color}
                    subNavHeaderBorderColor={sub_nav?.border_color}
                    subNavHeaderBgColor={sub_nav?.copy_background_color}
                    subNavProductCollection={subNavProductRender}
                    subNavImgInCollection={{
                        img: sub_nav?.image?.url,
                        imgSpot: sub_nav?.image_spot,
                    }}
                    productPerRow={productPerRow}
                    numOfProducts={numOfProducts}
                />
                {productData && (
                    <div className={classes.collectionWrapper}>
                        <div className={classes.guidedNav}>
                            <GraphqlLeftMenuContainer
                                guidedNavForm={guidedNavForm}
                                leftMenuData={contentData}
                                isMobile={isMobile}
                                guidedNavModalHasBeenActivated={guidedNavModalHasBeenActivated}
                                trackEvent={trackEvent}
                                isGuidedNavWidgetEnabledFlag={isGuidedNavWidgetEnabled}
                                isFacetFiltersEnabled={isFacetFiltersEnabled}
                                isFoodBrand={isFoodBrand}
                                productFacetsBase={productFacetsBase}
                                isFacetFiltersCountEnabled={isFacetFiltersCountEnabled}
                                filterFlag={filterFlag}
                                category={categoryData?.category || {}}
                            />
                        </div>

                        <div className={(guidedNavForm && isShowGuidedNav) ? classes.mainCollection : classes.mainCollectionWithoutGnav}>
                            <div className={classes.productRow}>
                                {productData.length ? productData.map((data, i) => (
                                    <>
                                        {products[i] && (
                                            <div className={simpleProductClass} key={seed(data)}>
                                                <SimpleProduct
                                                    name={data.name}
                                                    image={data.image}
                                                    url={data.seo?.url}
                                                    skuPriceRange={data.skuPriceRange}
                                                    availability={data.availability}
                                                    partNumber={data.partNumber}
                                                    showSimpleProductRedesignAbTest={false}
                                                    productBrand={data?.brandId}
                                                    isCategoryPage
                                                    productSkus={data?.productSkus}
                                                    isPassportEligible={data?.isPassportEligible}
                                                />
                                            </div>
                                        )}
                                        {icBannerBlock[i] && (
                                            <div className={classes.banner} key={seed(data)}>
                                                <GraphqlIcBanner icBannerBlock={icBannerBlock[i]} presentation_family={presentationFamily} />
                                            </div>
                                        )}
                                    </>
                                )) : null}
                            </div>
                        </div>
                    </div>
                )}
                {isMobile ?  (
                    <RailMenuTabletContainer
                        railMenuData={contentData}
                        isMobile={isMobile}
                    />
                )
                    : (
                        <>
                            <GraphqlDisclaimer disclaimerBlock={disclaimer} />
                        </>
                    )            }
            </Grid>
        </>
    );
};

TemplatePageSales.propTypes = {
    classes: object.isRequired,
    templateData: shape({
        header_sub_copy: string.isRequired,
        header: string.isRequired,
        category: shape({
            products: arrayOf(
                shape({
                    image: shape({
                        name: string,
                        altText: string,
                    }),
                    name: string,
                    seo: shape({
                        url: string,
                    }),
                    partNumber: string,
                }),
            ),
        }),
    }).isRequired,
    presentationFamily: string.isRequired,
    brand: object.isRequired,
    page: shape({
        path: string.isRequired,
    }).isRequired,
    isMobile: bool.isRequired,
    ssrDeviceType: string.isRequired,
    guidedNavModalHasBeenActivated: bool.isRequired,
    isFacetFiltersCountEnabled: bool.isRequired,
    isFoodBrand: bool.isRequired,
    isFacetFiltersEnabled: bool.isRequired,
    filterFlag: bool.isRequired,
    productFacetsBase: arrayOf(
        shape({
            name: string.isRequired,
            entries: arrayOf(
                shape({
                    name: string.isRequired,
                    value: string.isRequired,
                }),
            ).isRequired,
        }).isRequired,
    ).isRequired,
    productsData: array,
};

TemplatePageSales.defaultProps = {
    productsData: [],
};
const mapStateToProps = (state) => ({
    ssrDeviceType: getSSRDeviceType(state),
});
const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, null),
);

export default enhance(TemplatePageSales);
