/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import {
    string,
    func,
    bool,
    number,
    object,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ZipInputField from './Partials/ZipInputField';
import AddressZipField from './AddressZipField';
import useSalesforcePersonalizedContentQuery from '../../../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import useClickStreamCustomEventsTracking from '../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import AutoFillAddressSkeleton from '../../../GraphqlSkeletonComponents/AutoFillAddressSkeleton';
import { trackEvent } from '../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const GiftFindZipCodeBlock = ({
    zipcodeIsValid, zipLabel, giftFinderBodyTextColor,
    currentInputLength, value, handleChange,
    onSubmitCheck, userSubmittedZip, brandDomain,
    giftFinderCodeTheoryView, inputBorderColor, giftFinderFieldStyle,
}) => {
    const isGiftFinderAbAutofill = useSelector(getFeatureFlag('is-gift-finder-ab-autofill-test'));
    const dispatch = useDispatch();

    const { data, loading, variables } = useSalesforcePersonalizedContentQuery({
        queryName: ' gfAutoFillAddress',
        interactionName: `${brandDomain} - Get Campaign - Gift Finder Auto Fill Address`,
        featureFlag: isGiftFinderAbAutofill,
        optInHoldoutParticipation: false,
        resolveIdentity: false,
    });
    const gfAutoFillAddress = data?.contentSF?.campaign?.campaignResponses?.[0]?.payload?.userGroup;
    const gfAutoFillAddressEnabled = gfAutoFillAddress?.toLowerCase?.() === 'test';

    useClickStreamCustomEventsTracking({
        salesforceResponse: data?.contentSF,
        isFireImpression: true,
        page: { type: 'homepage' },
    });

    useEffect(() => {
        if (typeof gfAutoFillAddress !== 'undefined' && !window.wuAutoFillEventFired && gfAutoFillAddress) {
            // GA tracking
            const eventParam = {
                eventCategory: 'Experiment',
                eventAction: 'Gift Finder Auto Fill Address ',
                eventLabel: gfAutoFillAddressEnabled ? 'Variant' : 'Control',
                feature_detail: gfAutoFillAddressEnabled ? 'Variant' : 'Control',
                feature_element: 'Gift Finder Auto Fill Address',
                feature_category: 'Experiment',
            };
            dispatch(trackEvent(eventParam));
            window.wuAutoFillEventFired = true;
        }
        return () => {
            window.wuAutoFillEventFired = false;
        };
    }, [gfAutoFillAddress]);

    if (loading && variables?.skip) {
        return <AutoFillAddressSkeleton />;
    }

    return (
        <div>
            {gfAutoFillAddressEnabled
                ? (
                    <AddressZipField
                        zipLabel={zipLabel}
                        giftFinderBodyTextColor={giftFinderBodyTextColor}
                        gfAutoFillAddressEnabled={gfAutoFillAddressEnabled}
                        ziphandleChange={handleChange}
                        giftFinderCodeTheoryView={giftFinderCodeTheoryView}
                        giftFinderFieldStyle={giftFinderFieldStyle}
                        inputBorderColor={inputBorderColor}
                        onSubmitCheck={onSubmitCheck}
                    />
                )
                : (
                    <ZipInputField
                        zipLabel={zipLabel}
                        currentInputLength={currentInputLength}
                        handleChange={handleChange}
                        onSubmitCheck={onSubmitCheck}
                        zipcodeIsValid={zipcodeIsValid}
                        userSubmittedZip={userSubmittedZip}
                        value={value}
                        giftFinderBodyTextColor={giftFinderBodyTextColor}
                        giftFinderCodeTheoryView={giftFinderCodeTheoryView}
                        giftFinderFieldStyle={giftFinderFieldStyle}
                        inputBorderColor={inputBorderColor}
                    />
                )}
        </div>
    );
};

GiftFindZipCodeBlock.propTypes = {
    zipLabel: string.isRequired,
    currentInputLength: number.isRequired,
    handleChange: func.isRequired,
    onSubmitCheck: bool.isRequired,
    zipcodeIsValid: bool,
    userSubmittedZip: string.isRequired,
    value: string.isRequired,
    giftFinderBodyTextColor: string,
    brandDomain: string.isRequired,
    giftFinderCodeTheoryView: bool,
    giftFinderFieldStyle: object,
    inputBorderColor: string,
};

GiftFindZipCodeBlock.defaultProps = {
    zipcodeIsValid: null,
    giftFinderBodyTextColor: '',
    giftFinderCodeTheoryView: false,
    giftFinderFieldStyle: {},
    inputBorderColor: '',
};

export default GiftFindZipCodeBlock;
