/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useState, useEffect } from 'react';
import {
    object, shape, string, func, array, bool,
} from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router';
import ReactHtmlParser from 'react-html-parser';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import { Switch } from '@material-ui/core';
import { getActiveABTests } from '../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { getFeatureFlag, getPresentationFamily } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import scrollToTop from './scrollToTop';
// import noop from '../../../../../helpers/noop';

const ProductFacetsView = ({
    facetsView, checkedOptions, presentationFamily, activeABTests,
    setCheckedOptions, history, isFacetFiltersCountEnabled,
    track, filterOn, category, isAggregatorFacetErrorResolver, classes, iconType, displayOpen, setOpenedFacets,
}) => {
    const [open, setOpen] = useState(displayOpen || false);
    const [countList, setCountList] = useState(3);
    const [isCollapsed, setCollapsed] = useState(facetsView.entries.length > 4);

    const handleClick = (openForce = false) => {
        setOpen(openForce || !open);
    };
    const handleViewMore = (count) => {
        setCollapsed(!isCollapsed);
        setCountList(!isCollapsed ? 3 : count);
    };
    useEffect(() => {
        if (facetsView?.entries.length && checkedOptions.length) {
            checkedOptions.forEach((facet) => facetsView.entries.forEach((val, index) => {
                if (val.value === facet) {
                    if (index >= 4) {
                        handleViewMore(facetsView.entries.length);
                    }
                }
            }));
        }
    }, []);

    const  labelContent = (data) => (
        <div className={classes.facetsValuesContainer}>
            <Typography
                component="span"
                variant="body2"
                className={classes.facetsValues}
                color="textPrimary"
            >
                {ReactHtmlParser(data?.name)}
            </Typography>
            {isFacetFiltersCountEnabled && (
                <Typography
                    className={classes.facetNumber}
                    component="span"
                    color="textPrimary"
                >
                    ({data?.count})
                </Typography>
            )}
        </div>
    );
    const setFacetsFilterData = (e, data = null) => {
        const value = e.target.value;
        let checkedArr = [];

        if (checkedOptions && value) {
            checkedArr = [...checkedOptions];
            if (checkedArr.some((check) => check === value)) {
                checkedArr = checkedArr.filter((check) => check !== value);
            } else {
                checkedArr.push(value);
            }
            setCheckedOptions(checkedArr);
        }

        let delimiter = '&';
        if (isAggregatorFacetErrorResolver) {
            delimiter = '&facet=';
        }

        const urlSelectedParams = checkedArr.join(delimiter);
        // Fire facet interaction event
        if (category && checkedArr.some((check) => check === value) && data && (filterOn || '').toLowerCase() !== 'search') {
            const { id, name } = category;
            const trackData = {
                eventCategory: 'Collection Page',
                eventAction: `Filter Interaction - ${name}`,
                eventLabel: data.value,
                category_name: name,
                category_id: id,
                filters_product_count: data.count,
                filters_page_url: urlSelectedParams,
                eventName: 'apply_filter',
                filter_name: data?.name || '',
            };
            track(trackData);
        } else if ((filterOn || '').toLowerCase() === 'search')  {
            const trackData = {
                eventCategory: 'Search Page',
                eventAction: `Filter Interaction - ${data?.name || data?.value}`,
                eventLabel: data?.value,
                filters_product_count: data?.count,
                filters_page_url: urlSelectedParams,
                eventName: 'apply_filter',
                filter_name: data?.name  || '',
            };
            track(trackData);
        }
        scrollToTop();
    };

    const loadListItem = (data, isChecked, personalizedFacet) => (
        <FormControl key={data.value} className={classes.nested}>
            <FormGroup>
                <FormControlLabel
                    control={(
                        !personalizedFacet
                            ? (
                                <Checkbox
                                    color="primary"
                                    disableRipple
                                    name="facetsViewCheckbox"
                                    value={data.value}
                                    checked={isChecked}
                                    icon={<span className={classes.filterChecked} />}
                                    classes={{
                                        root: classes.facetsCheckbox,
                                    }}
                                    onChange={(e) => setFacetsFilterData(e, data)}
                                />
                            )
                            : <Switch color="primary" value={data.value} checked={isChecked} onChange={(e) => setFacetsFilterData(e, data)} />
                    )}
                    label={!personalizedFacet && labelContent(data)}
                />
            </FormGroup>
        </FormControl>
    );

    const filterFacetsName = facetsView?.name;
    const facetsLength = facetsView?.entries?.length;
    const facetName = filterFacetsName === 'Recipient Type' || filterFacetsName === 'Occasion';
    const collectionUrl = history?.location?.pathname === '/all-flower-arrangements' || history?.location?.pathname === '/plants';
    const facetsABTest = activeABTests?.show_filter_facets === 'Variant' && facetName;
    const showFilterAndFacets = filterFacetsName !== 'OfferPrice_USD' && filterFacetsName !== 'ParentCatalogGroup' && facetsView?.entries?.length > 0;
    const personalizedFacet = filterFacetsName === 'Personalized' && presentationFamily === 'flower';
    let expandButton = {};
    if (iconType === 'primary') {
        expandButton = (open ? <ExpandLess data-testid="expand-more-icon" /> : <ExpandMore data-testid="expand-less-icon" />);
    } else {
        expandButton = (open ? <Remove data-testid="remove-icon" /> : <Add data-testid="add-icon" />);
    }
    const  expandViewMore = (isCollapsed  ? <><Add data-testid="add-icon" /><span>More</span></> : <> <Remove data-testid="remove-icon" /> <span>Less</span></>);
    const hidePriceRange = history?.location?.pathname === '/flowers-gifts-vanguard-080321' && filterFacetsName === 'Price Range';
    return (
        <>
            {showFilterAndFacets && (!collectionUrl || (collectionUrl && (facetsABTest || !facetName))) && !hidePriceRange
                    && (
                        <div className={!personalizedFacet ? classes.rootListItem : `${classes.personalizedRootListItem} ${classes.rootListItem}`}>
                            <div onClick={() => { handleClick(false); setOpenedFacets(filterFacetsName); }} onKeyDown={() => { handleClick(false); setOpenedFacets(filterFacetsName); }} className={classes.ListItem} styles={{ selected: classes.active }} data-testid={facetsView.name} role="button" tabIndex={0}>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.facetsName}
                                    color="textPrimary"
                                >
                                    {filterFacetsName}
                                </Typography>
                                {!personalizedFacet && expandButton}
                            </div>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <div component="div" disablePadding className={facetsLength > 9 ? classes.rootFacetsValueOverflow : classes.rootFacetsValues}>
                                    {facetsView ? facetsView.entries.map((data, index) => {
                                        let isChecked = false;
                                        if (checkedOptions?.length) {
                                            isChecked =  checkedOptions.includes(data?.value);
                                        }
                                        return (
                                            <>
                                                {countList >= index && loadListItem(data, isChecked, personalizedFacet) }
                                            </>
                                        );
                                    }) : null}
                                    {(facetsLength > 4) && <div role="presentation" className={classes.listMore} onClick={() => handleViewMore(facetsView?.entries?.length)}>{expandViewMore}</div>}
                                </div>
                            </Collapse>
                        </div>
                    )}
        </>

    );
};
ProductFacetsView.defaultProps = {
    iconType: 'primary',
    track: () => {},
    filterOn: '',
    activeABTests: {},
    isAggregatorFacetErrorResolver: false,
    setOpenedFacets: () => {},
};
ProductFacetsView.propTypes = {
    classes: object.isRequired,
    history: object.isRequired,
    facetsView: shape({
        name: string.isRequired,
    }).isRequired,
    checkedOptions: array.isRequired,
    setCheckedOptions: func.isRequired,
    iconType: string,
    isFacetFiltersCountEnabled: bool.isRequired,
    track: func,
    filterOn: string,
    category: object.isRequired,
    activeABTests: shape({
        show_filter_facets: string,
    }),
    presentationFamily: string.isRequired,
    isAggregatorFacetErrorResolver: bool,
    displayOpen: bool.isRequired,
    setOpenedFacets: func,
};
const mapStateToProps = (state) => ({
    activeABTests: getActiveABTests(state),
    presentationFamily: getPresentationFamily(state),
    isAggregatorFacetErrorResolver: getFeatureFlag('is-facet-throwing-error-resolver-enabled')(state),
});

export default withRouter(connect(mapStateToProps)(ProductFacetsView));
