/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
    object, shape, arrayOf, string, number, array,
    bool,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router';
import Media from 'react-media';
import DesktopMobile from '../../../../../../../helpers/DesktopMobile/DesktopMobile';
import DesktopTabletImoc from './Partials/DesktopTabletImoc/DesktopTabletImoc';
import MobileImoc from './Partials/MobileImoc/MobileImoc';
import { trackEvent } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getPageViewFiredState } from '../../../../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';
import useSalesforcePersonalizedContentQuery from '../../../../../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import GenericSkeleton from '../../../../../GraphqlSkeletonComponents/GenericSkeleton';
import { getBrand } from '../../../../../../../pages/Account/state/ducks/Common/Common-Selectors';
import { getFeatureFlag } from '../../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import ResponsiveImage from '../../../../../GraphqlCommonComponents/ResponsiveImage/ResponsiveImage';
import useClickStreamCustomEventsTracking from '../../../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';

/*
    If viewport is add in any one of IMOC, only that IMOC will be displayed on the selected viewport
    If no viewport is selected on any IMOC, last imoc will be displayed on both desktop and mobile (backward compatibility)
*/
const useStyles = makeStyles((theme) => ({
    imocImage: {
        width: '100%',
    },
}));

const GraphqlImoc = ({
    imocBlock, brand, samedayCutOff, isSameDayCollectionV2,
}) => {
    const classes = useStyles();
    const location = useLocation();
    const imoc_group = imocBlock?.imoc_group.length > 0 ? imocBlock?.imoc_group : [imocBlock?.imoc_group] || [];
    // initially assign value to both mobile and desktop (backward compatibility)
    let mobileBlock = imoc_group;
    let desktopBlock = imoc_group;

    // check if viewport is added to any one of IMOC block. Assign that imoc block or assign null if no viewport is selected
    if (imoc_group?.some((block) => block?.viewport?.length && imoc_group?.some((group) => group?.viewport?.[0]?.select))) {
        mobileBlock = imoc_group.filter((block) => block.viewport[0]?.select?.includes('Mobile')) || null;
        desktopBlock = imoc_group.filter((block) => block.viewport[0]?.select?.includes('Desktop')) || null;
    }
    const dispatch = useDispatch();
    const { whichSameDayCollection } = useFlags();
    const pageView = useSelector(getPageViewFiredState);
    const brandName = useSelector(getBrand);
    const collectionPageImocUrls = useSelector(getFeatureFlag('which-collection-page-imoc-test'));
    const currentPath = location.pathname; // Replace with location.pathname in React Router if applicable
    const collectionImocAbTestFlag = collectionPageImocUrls && collectionPageImocUrls?.includedPathList?.some((item) => item?.path === currentPath);
    const desktopFullImocImage = desktopBlock?.[0]?.full_width_banner_image?.desktop_image || {};
    const mobileFullImocImage = mobileBlock?.[0]?.full_width_banner_image?.mobile_image || {};
    useEffect(() => {
        if (samedayCutOff) {
            if (!isSameDayCollectionV2) {
                dispatch(trackEvent({
                    eventCategory: 'Experiment',
                    eventAction: 'Sameday collection',
                    eventLabel: 'Control',
                }));
            }
            // else if (typeof whichSameDayCollection === 'string') {
            //     dispatch(trackEvent({
            //         eventCategory: 'Experiment',
            //         eventAction: 'Sameday collection',
            //         eventLabel: 'Control',
            //     }));
            // } // adding this to child component
        }
    }, [whichSameDayCollection, pageView]);
    const { loading, variables, data } = useSalesforcePersonalizedContentQuery({
        queryName: 'graphqlCollectionImoc',
        interactionName: `${brandName?.domain} - Get Campaign - Imoc Promo`,
        featureFlag: collectionImocAbTestFlag,
        optInHoldoutParticipation: false,
        resolveIdentity: false,
    });
    useClickStreamCustomEventsTracking({
        salesforceResponse: data?.contentSF,
        isFireImpression: true,
        page: 'category',
    });

    useEffect(() => {
        if (typeof data !== 'undefined' && !window.wuGraphqlImocEventFired && data) {
            // GA tracking
            const eventParam = {
                eventCategory: 'Experiment',
                eventAction: 'Graphql Collection Imoc',
                eventLabel: data ? 'Variant' : 'Control',
                feature_detail: data ? 'Variant' : 'Control',
                feature_element: 'Graphql Collection Imoc',
                feature_category: 'Experiment',
            };
            trackEvent(eventParam);
            window.wuGraphqlImocEventFired = true;
        }
        return () => {
            window.wuGraphqlImocEventFired = false;
        };
    }, [data]);
    const collectionImoc = data?.contentSF?.campaign?.campaignResponses?.[0]?.payload?.userGroup;
    if (loading || variables?.skip) {
        return (
            <GenericSkeleton height="100px" />
        );
    }
    if (collectionImoc?.toLowerCase() === 'test') {
        return (
            <>
                <Media
                    query="(min-width: 599px)"
                >
                    {(matches) => (matches
                        ? (
                            <ResponsiveImage
                                className={classes.imocImage}
                                path={desktopFullImocImage?.url}
                                alt={desktopFullImocImage?.title}
                            />
                        )

                        :     (
                            <ResponsiveImage
                                className={classes.imocImage}
                                path={mobileFullImocImage?.url}
                                alt={mobileFullImocImage?.title}
                            />
                        )

                    )}
                </Media>
            </>
        );
    }
    return (
        <>
            <DesktopMobile
                mobile={() => <MobileImoc imocBlock={mobileBlock} samedayCutOff={samedayCutOff} isSameDayCollectionV2={isSameDayCollectionV2} />}
                desktop={() => <DesktopTabletImoc imocBlock={desktopBlock} brand={brand} samedayCutOff={samedayCutOff} isSameDayCollectionV2={isSameDayCollectionV2} />}
                breakpoint={600}
                ignoreHover
            />
        </>
    );
};

GraphqlImoc.propTypes = {
    imocBlock: shape({
        imoc_group: arrayOf(
            shape({
                layout: string,
                header: string.isRequired,
                copy: string.isRequired,
                read_more_copy: string,
                image: object,
                offer_section: object,
                font_color: string,
                font_size: number,
            }),
        ).isRequired,
    }).isRequired,
    brand: string.isRequired,
    samedayCutOff: shape({
        entries: arrayOf(shape({
            cutoff_entries: array,
        })),
    }),
    isSameDayCollectionV2: bool,
};

GraphqlImoc.defaultProps = {
    samedayCutOff: {},
    isSameDayCollectionV2: false,
};

export default GraphqlImoc;
