/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string,
} from 'prop-types';
import { useQuery } from '@apollo/client';
import mbpLogger from 'mbp-logger';
import { GRAPHQL_ENV } from '../../../../../gql';
import findContentByUID from '../../../../../gql/queries/findContentByUID';
import SingleProduct from '../../../UniversalTemplate/Components/Features/Standard/SingleProduct';
// import Features from '../../../UniversalTemplate/Components/Features/Features';

const FeatureLoader = ({
    uid, contentid, brand,
}) => {
    if (!uid.length) return null;

    const FEATURE_QUERY = findContentByUID({
        brand: brand.domain || '1800flowers',
        uid,
        // eslint-disable-next-line no-underscore-dangle
        contentType: contentid,
    });

    const { data, error, loading } = useQuery(FEATURE_QUERY);
    if (loading) return <></>;

    if (error) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: FEATURE_QUERY,
            component: 'FeatureLoader.js',
            message: 'Error in query',
            env: GRAPHQL_ENV,
        });
        return null;
    }
    if (!data) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: FEATURE_QUERY,
            component: 'FeatureLoader.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
        return null;
    }
    if (data) {
        const reference = { reference: [data.findContentByUID.content.entry] };
        const bannerData = {
            product_feature: reference,
        };
        return (
            <SingleProduct data={bannerData} />
        );
    }

    return null;
};
FeatureLoader.defaultProps = {
    uid: '',
    contentid: '',
    brand: '1800flowers',
};
FeatureLoader.propTypes = {
    uid: string,
    contentid: string,
    brand: string,
};
export default FeatureLoader;
